<template>
  <div v-if="address && address.data.length">
    <ui-checkbox-group
            class="form"
            @on-change="changeBoxGroup"
            v-model:value.sync="checked1"
    >
      <li class="item-wapper"  :ref="addressCard" v-for="(item,index) in address.data" :key="index">

          <a href="javaScript:;" class="item-content">

            <div class="item address-item">
              <ui-checkbox
                      size="large"
                      class="item-checkbox-wapper"
                      :label="item.postId"
              >
              </ui-checkbox>
              <div style="margin-right: 0.5333rem;">{{ item.userName }}</div>
              <div>{{ item.mobile }}</div>
              <div class="default" v-if="item.effective">默认</div>
            </div>
            <div class="item">
              <div class="left tip address-detail" style="margin-left: 44px">
                {{ item.province }} {{ item.city }} {{ item.district }} {{ item.postAdd }}
              </div>
            </div>

            <div class="submit-order-icon">
              <img src="@/assets/img/index/icon20.png" />
            </div>
          </a>
          <div class="item-operation">
            <router-link class="edit" :to="{ name: 'addAddressOfUser', query: { id: item.postId } }">
              编辑
            </router-link>
            <div class="del" @click="delAddress(item.postId)">作废</div>
          </div>


      </li>

    </ui-checkbox-group>
  </div>

</template>

<script lang="ts">
  import {defineComponent, watch, nextTick, reactive, ref, computed, onMounted, PropType} from "vue";
  import useAddress from "@/hooks/useAddress";
  import Modal from "@/components/UI/Modal";
  import qs from "qs";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import UiCheckbox from "@/components/UI/Checkbox";
  import UiCheckboxGroup from "@/components/UI/CheckboxGroup";
  export default defineComponent({
    name: "addressCardOfNei",
    props: {
      address: {
        type: Object,
        default: () => {
          return {};
        },
      },
      modelValue: {
        type: Array as PropType<string[]>,
        default: () => {
          return [];
        },
      },
    },
    components: {
      UiCheckbox,
      UiCheckboxGroup,
    },
    setup(props, ctx) {

      const checked1 = ref<string[]>([]);
      const nativeCheck = computed(() => {
        return props.modelValue;
      });
      function setCheckValue() {
        checked1.value = nativeCheck.value;
      }
      watch(nativeCheck, () => {
        setCheckValue();
      });
      const changeBoxGroup = (val: any) => {
        if (checked1.value.length > 1) {
          checked1.value.shift();
        }
        ctx.emit("update:modelValue", val);
      };

      onMounted(() => {
        setCheckValue();
      });

      let refs: Array<HTMLElement> = [];
      const addressCard = (el: HTMLElement) => {
        refs.push(el);
      }
      watch(() => props.address, ()=>{
        refs = [];
        if(props.address.data.length > 0){
          nextTick(() => {
            props.address.data.map((item: any, index: string | number) => {
              if (item.effective) {
                  checked1.value.push(item.postId);
                }
              useAddress(refs[index])
            })
          })
        }
      })

      function delAddress(id: string){
        Modal({
          title: "温馨提示",
          content: "确认作废本地址?",
          onConfirm: () => {
            const data = qs.stringify({
              postId:id,
              openInvoiceType:1
            });
            axios.post("/M/AokeNei/setDefaultAddressOfNei", data).then(res => {
              console.log(res)
              Toast({
                type: "success",
                title: "操作成功！",
              });
              window.location.reload()
            }).catch(err => {
              console.log(err)
            })
          },
          onCancel: () => {
            console.log('取消')
          },
        });
      }

      return {
        addressCard,
        delAddress,
        checked1,
        changeBoxGroup,
      };
    },
  });
</script>
<style lang="scss">
  .zm-checkbox__inner{
    border-radius: 50%;
  }
</style>
<style lang="scss" scoped>

  .item-checkbox-wapper {
    width: 35px;
    height: 35px;
    box-sizing: border-box;
    /*border-bottom: 7.5px solid #f4f4f4;*/
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    padding: 15px;
    :deep(.zm-checkbox__label) {
      display: block;
    }
  }


  .item-wapper{
    width: 375px;

    height: auto;
    box-sizing: border-box;
    border-bottom: 7.5px solid #f4f4f4;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: space-between;
  }


  .form .item-content{

    color: #444444;
    font-size: 15px;
    display: block;
    width: 100%;
    height: 100%;
    // padding: 15px;
    overflow: hidden;
    box-sizing: border-box;
  }

  .item-content{
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: translateX(130.013px);
    transform: translateX(130.013px);

    width: 100%;
    margin-right: 0;
    margin-left: -130.013px;
    padding: 0 15px;
    box-sizing: border-box;
  }


  .item-operation{
    width: 130.013px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    -webkit-transform: translateX(130.013px);
    transform: translateX(130.013px);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
  .edit{
    width: 64.987px;
    float: left;
    height: 90px;
    background: #b9b9b9;
    color: #fff;
    font-size: 12px;
    line-height: 90px;
    text-align: center;
  }

  .del{
    width: 64.987px;
    float: right;
    height: 90px;
    background: #d64b4b;
    color: #fff;
    font-size: 12px;
    line-height: 90px;
    text-align: center;
  }

  .moving .item-content,
  .moving .item-operation {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .moving{
    width: 375px;
    height: auto;
    box-sizing: border-box;
    border-bottom: 7.5px solid #f4f4f4;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  ul li{

    border-bottom: 6px solid #f4f4f4;
  }

  .item{
    overflow: hidden;
    margin: 15px 0;

  }
  .item .address-detail{
    width: 9.2rem;
  }


  .item .left{
    float: left;

  }

  .address-item{
    display: flex;
    align-items: center;
  }


  .tip{
    font-size: 13.012px;
    color: #cccccc;
  }
  .item .right{
    float: left;
    margin-left: 19.988px;
    color: #999;
  }

  .default{
    border-radius: 4px;
    width: 42px;
    height: 22px;
    line-height: 22px;
    margin-left: 10px;
    border: solid 1px #df0024;
    text-align: center;
    font-size: 13px;
    color: #df0024;
    display: inline-block;
  }

  .item img{
    width: 6px;
    height: 9.488px;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }

  .submit-order-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    width: 9px;
    height: 16px;
    margin-top: -8px;
  }

  .submit-order-icon img {
    width: 9px;
    height: 16px;
  }




</style>

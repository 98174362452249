
  import {defineComponent, watch, nextTick, reactive, ref, computed, onMounted, PropType} from "vue";
  import useAddress from "@/hooks/useAddress";
  import Modal from "@/components/UI/Modal";
  import qs from "qs";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import UiCheckbox from "@/components/UI/Checkbox";
  import UiCheckboxGroup from "@/components/UI/CheckboxGroup";
  export default defineComponent({
    name: "addressCardOfNei",
    props: {
      address: {
        type: Object,
        default: () => {
          return {};
        },
      },
      modelValue: {
        type: Array as PropType<string[]>,
        default: () => {
          return [];
        },
      },
    },
    components: {
      UiCheckbox,
      UiCheckboxGroup,
    },
    setup(props, ctx) {

      const checked1 = ref<string[]>([]);
      const nativeCheck = computed(() => {
        return props.modelValue;
      });
      function setCheckValue() {
        checked1.value = nativeCheck.value;
      }
      watch(nativeCheck, () => {
        setCheckValue();
      });
      const changeBoxGroup = (val: any) => {
        if (checked1.value.length > 1) {
          checked1.value.shift();
        }
        ctx.emit("update:modelValue", val);
      };

      onMounted(() => {
        setCheckValue();
      });

      let refs: Array<HTMLElement> = [];
      const addressCard = (el: HTMLElement) => {
        refs.push(el);
      }
      watch(() => props.address, ()=>{
        refs = [];
        if(props.address.data.length > 0){
          nextTick(() => {
            props.address.data.map((item: any, index: string | number) => {
              if (item.effective) {
                  checked1.value.push(item.postId);
                }
              useAddress(refs[index])
            })
          })
        }
      })

      function delAddress(id: string){
        Modal({
          title: "温馨提示",
          content: "确认作废本地址?",
          onConfirm: () => {
            const data = qs.stringify({
              postId:id,
              openInvoiceType:1
            });
            axios.post("/M/AokeNei/setDefaultAddressOfNei", data).then(res => {
              console.log(res)
              Toast({
                type: "success",
                title: "操作成功！",
              });
              window.location.reload()
            }).catch(err => {
              console.log(err)
            })
          },
          onCancel: () => {
            console.log('取消')
          },
        });
      }

      return {
        addressCard,
        delAddress,
        checked1,
        changeBoxGroup,
      };
    },
  });


const useAddress = (el: HTMLElement) => {

    let x = 0, y = 0, X = 0, Y = 0, swipeX = false, swipeY = false;
    el.addEventListener('touchstart', (event: any) => {
        x = event.changedTouches[0].pageX;
        y = event.changedTouches[0].pageY;
        swipeX = true;
        swipeY = true;
    });
    el.addEventListener('touchmove', (event: any) => {
        X = event.changedTouches[0].pageX;
        Y = event.changedTouches[0].pageY;
        // 左右滑动
        if (swipeX && Math.abs(X - x) - Math.abs(Y - y) > 0) {
            // 阻止事件冒泡
            event.stopPropagation();
            if (X - x > 10) {   //右滑
                el.className = "item-wapper";    //右滑收起
            }
            if (x - X > 10) {   //左滑
                //event.preventDefault();
                el.className = "moving";   //左滑展开
                //expansion = this;
            }
            swipeY = false;
        }
        // 上下滑动
        if (swipeY && Math.abs(X - x) - Math.abs(Y - y) < 0) {
            swipeX = false;
        }
    });


}

export default useAddress